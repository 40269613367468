import {Chart, ChartData, ChartItem, ChartOptions, ChartType, UpdateMode} from "chart.js";
import {ChartDataset} from "chart.js/auto";

export class ChartJs {
    private _chart;
    private _selectedDatasetIndex: number;

    constructor(chartCanvas: HTMLElement,
                type: ChartType,
                data: ChartData,
                selectedDatasetIndex: number,
                options?: ChartOptions,
    ) {
        this._selectedDatasetIndex = selectedDatasetIndex;
        this._chart = new Chart(chartCanvas as ChartItem, {
            type: type,
            data: data,
            options: options
        });
    }

    updateOptions(options: ChartOptions) {
        this._chart.options = options;
    }

    show(datasetIndex: number, dataIndex?: number) {
        this._selectedDatasetIndex = datasetIndex;
        this._chart.show(datasetIndex, dataIndex);
    }

    hide(datasetIndex: number, dataIndex?: number) {
        this._chart.hide(datasetIndex, dataIndex);
    }

    update(mode?: UpdateMode) {
        this._chart.update(mode);
    }

    getSelectedDatasetIndex(): number {
        return this._selectedDatasetIndex;
    }

    getDatasets(): ChartDataset[] {
        return this._chart.data.datasets;
    }

    updateDatasets(datasets: ChartDataset[]) {
        this._chart.data.datasets = datasets
        this._chart.update();
    }
}
