import ChatsData from "../types/ChatsData";
import DateFormatter from "./DateFormatter";

export interface WhatsappTrackingRequest {
    channel: string;
    brandId: number;
    languageCode: string;
    countryCode: string;
    detailPageUrl: string;
    internalStatsApplyData: {
        deviceType: string;
        userAgent: string;
        ipAddress: string;
        originId: number;
        pageViewId: string;
        sectionId: number;
        adId: string;
        message?: string | null;
        rentStartDate?: string | null;
        rentEndDate?: string | null;
    };
}

export default class WhatsappService {
    private readonly CHAT_API_URI_ENDPOINT: string = '/adform/api/conversation/';

    public getWhatsappData = (
        section: number,
        chatData: ChatsData,
        country: string,
        device: string,
        adId: string,
        pageViewId: string,
        message: string | null = '',
        rentStartDate: Date | null = null,
        rentEndDate: Date | null = null
    ) => {
        const whatsappTrackingRequest: WhatsappTrackingRequest = {
            channel: 'whatsapp',
            brandId: chatData.branId,
            languageCode: chatData.countryLanguage.toLowerCase(),
            countryCode: country.toLowerCase(),
            detailPageUrl: chatData.adUrl,
            internalStatsApplyData: {
                deviceType: device.toLowerCase(),
                userAgent: chatData.userAgent,
                ipAddress: chatData.ipAddress,
                originId: chatData.origin,
                pageViewId: pageViewId,
                sectionId: section,
                adId: adId,
                message: message,
            }
        };
        if (rentStartDate && rentEndDate) {
            whatsappTrackingRequest.internalStatsApplyData.rentStartDate = this.formatDate(rentStartDate);
            whatsappTrackingRequest.internalStatsApplyData.rentEndDate = this.formatDate(rentEndDate);
        }
        return fetch(this.CHAT_API_URI_ENDPOINT + adId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(whatsappTrackingRequest),
        });
    };

    private formatDate(date?: Date | null) : string | null {
        return DateFormatter.formatDate(date);
    }
}
