import {MarketStatsChart, MarketStatsConfig, MarketStatsStyles} from "./MarketStatsChart";
import {ChartSelectorSubscriber} from "./ChartSelectorSubscriber";

export class SerpMarketStatsChart {

   init(styles: MarketStatsStyles,
         selector: string = '.js-market-stats-chart',
         marketStatsStyle: MarketStatsChartVersion = MarketStatsChartVersion.WITH_POINTS,
         chartSelectorSubscriber?: ChartSelectorSubscriber,
    ) {
        const chartCanvas = document.querySelector(selector) as HTMLElement;
        this.createMarketStatsChart(chartCanvas, styles, chartSelectorSubscriber, marketStatsStyle);
    }

    private createMarketStatsChart(chartCanvas: HTMLElement | null,
                                   styles: MarketStatsStyles,
                                   chartSelectorSubscriber: ChartSelectorSubscriber | undefined,
                                   marketStatsStyle: MarketStatsChartVersion,
    ) {
        if (chartCanvas === null) {
            return;
        }
        const stats = chartCanvas.dataset.chartStats;
        if (stats === undefined) {
            return;
        }
        const statsDataset = JSON.parse(stats);
        const config = chartCanvas.dataset.chartConfig;
        if (config === undefined) {
            return;
        }
        const chartConfig: MarketStatsConfig = JSON.parse(config) as MarketStatsConfig;
        if (chartSelectorSubscriber !== undefined) {
            new MarketStatsChart(chartCanvas, statsDataset, chartConfig, styles, marketStatsStyle, chartSelectorSubscriber);
        } else {
            new MarketStatsChart(chartCanvas, statsDataset, chartConfig, styles, marketStatsStyle );
        }
    }
}

export enum MarketStatsChartVersion {
    WITH_POINTS = "with_points",
    NO_POINTS = "no_points",
}
