import {InputErrorManager, InputValidationHandler, InputValidator} from "./InputValidationHandler";
import FormValidations from "./FormValidations";
import {ErrorVisibilityManager} from "./ErrorVisibilityManager";

export class MessageValidationHandler implements InputValidator, InputErrorManager{
    private constructor() {
    }

    static create(element: HTMLTextAreaElement) {
        const nameValidationHandler = new MessageValidationHandler()
        return new InputValidationHandler(
            element,
            nameValidationHandler,
            nameValidationHandler
        );
    }

    isValid(element: HTMLTextAreaElement): boolean {
        if (element.required) {
            return FormValidations.isNotEmpty(element.value)
        }
        return true
    }

    manageError(element: HTMLTextAreaElement, isValid: boolean): void {
        ErrorVisibilityManager.manageErrorVisibility(element, isValid);
    }
}
