import {Device} from '../types/Device';
import AdFormService from '../services/AdFormService';
import {Section} from '../services/Section';
import PageData from '../PageData';

export class FormActions {
  private readonly pageData : PageData;

  constructor(pageData: PageData) {
    this.pageData = pageData;
  }

  public highlightForm = (section: Section) => {
    const formContainer = (document.querySelector('div[data-highlighted=\'true\']') as HTMLElement);
    formContainer.dataset.highlightedForm = 'true';
    if (this.pageData.device === Device.MOBILE) {
      if (formContainer) formContainer.scrollIntoView({ block: 'center' });
    } else {
      formContainer.classList.add('highlighted-form');
    }
    const customEvent = new CustomEvent('fromObfuscatedContactElement', { detail: section });
    document.dispatchEvent(customEvent);
    new AdFormService().applyImpression(this.pageData, section, 2);
  };
}
