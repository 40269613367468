export class InputValidationHandler {
    private readonly element: HTMLInputElement | HTMLTextAreaElement;
    private readonly inputValidator: InputValidator;
    private readonly errorManager: InputErrorManager;

    constructor(element: HTMLInputElement | HTMLTextAreaElement, inputValidator: InputValidator, errorManager: InputErrorManager) {
        this.element = element;
        this.inputValidator = inputValidator;
        this.errorManager = errorManager;
    }

    validate(): boolean {
        const isValid = this.isValid();
        this.manageError(isValid);
        return isValid;
    }

    private isValid() {
        return this.inputValidator.isValid(this.element);
    }

    private manageError(isValid: boolean) {
        this.errorManager.manageError(this.element, isValid);
    }
}

export interface InputValidator {
    isValid(element: HTMLInputElement | HTMLTextAreaElement): boolean;
}

export interface InputErrorManager {
    manageError(element: HTMLInputElement | HTMLTextAreaElement, isValid: boolean): void;
}