import PageData from "../../common/ts/PageData";
import ImageGallery from "../../common/ts/components/ImageGallery";
import {EventBus} from "../../common/ts/events/EventBus";
import HipflatPage from "./common/HipflatPage";

import {
    GalleryFullScreenGalleryClosedEvent,
    GalleryFullScreenGalleryOpenedEvent,
    GalleryPhotoClickedEvent,
    GalleryReloadNavigationEvent,
    ListingHighlightClickEvent
} from "../../common/ts/events/Events";
import AdFormService from "../../common/ts/services/AdFormService";
import {Section} from "../../common/ts/services/Section";

export default class HipflatModalPhotosSection {
    private readonly eventBus: EventBus;
    private readonly adFormService: AdFormService;
    private swiperModalPhotos: ImageGallery;
    private swiperModalFloorPlans;
    private readonly modalPhotos;
    private page: HipflatPage = new HipflatPage();
    private readonly pageViewId;
    private readonly pageViewType;
    private readonly propertyAdId: string;

    constructor(pageData: PageData) {
        this.modalPhotos = document.getElementById("modal-photos") as HTMLBodyElement;
        this.eventBus = EventBus.getInstance();
        this.adFormService = new AdFormService();
        this.pageViewId = pageData.pageViewId;
        this.pageViewType = pageData.pageViewType;
        this.propertyAdId = pageData.id;
        this.swiperModalPhotos = ImageGallery.init(pageData, '.swiper-modal-photos', true);
        this.swiperModalFloorPlans = ImageGallery.init(pageData, '.swiper-modal-floor-plans', true);

        if (!this.modalPhotos) return

        document.body.appendChild(this.modalPhotos);

        this.eventBus.subscribe(GalleryPhotoClickedEvent.TYPE, async (event) => {
            const eventElement: GalleryPhotoClickedEvent = event as GalleryPhotoClickedEvent
            const slideActive: number = eventElement.payload.slideActive;
            this.sendApplyImpressionForButtons();
            this.sendApplyImpressionForVisitTab();
            this.openModalPhotos();
            this.swiperModalPhotos.goToSlide(slideActive);
        });

        this.eventBus.subscribe(ListingHighlightClickEvent.TYPE, async (event) => {
            const eventElement: ListingHighlightClickEvent = event as ListingHighlightClickEvent
            if (eventElement.payload.id == "listing-highlight-floor-plans"){
                this.openModalFloorPlans();
            }
        });

        document.getElementById("close-modal-photos")?.addEventListener('click', () => {
            this.closeModalPhotos();
        });

        document.getElementById("listing-highlight-photos")?.addEventListener('click', () => {
            let swiperMobilePhotos = ImageGallery.init(pageData, '.swiper-mobile-photos');
            this.eventBus.emit(new GalleryPhotoClickedEvent({
                slideActive: swiperMobilePhotos.getSlideActive(),
            }));
        });

        document.querySelectorAll(".modal-photos__tabs__label").forEach((element) => {
            element.addEventListener('click', () => {
                this.swiperModalPhotos.reloadNavigation();
                this.galleryNavigationReload();
            });
        });

        document.getElementById("tab-photos")?.addEventListener('change', (event) => {
            const element = event.target as HTMLInputElement;
            if (element.checked) {
                this.sendApplyImpressionForButtons();
                this.sendApplyImpressionForVisitTab();
            }
        });
    }

    static init = (pageData: PageData) => {
        return new HipflatModalPhotosSection(pageData);
    };

    openModalGalleries() {
        this.page.block();
        this.modalPhotos?.classList.add("visible");
        this.eventBus.emit(new GalleryFullScreenGalleryOpenedEvent());
    }

    openModalPhotos() {
        this.openModalGalleries();
        (document.getElementById("tab-photos") as HTMLInputElement).checked = true;
    }

    openModalFloorPlans() {
        this.openModalGalleries();
        (document.getElementById("tab-floor-plans") as HTMLInputElement).checked = true;
    }

    closeModalPhotos() {
        this.page.unblock();
        this.modalPhotos?.classList.remove("visible");
        this.eventBus.emit(new GalleryFullScreenGalleryClosedEvent());
        this.galleryNavigationReload();
    }

    galleryNavigationReload() {
        this.eventBus.emit(new GalleryReloadNavigationEvent({}));
    }

    sendApplyImpressionForButtons() {
        if (this.modalPhotos.querySelector("#request-info-send-button")) {
            this.sendApplyImpression(Section.SECTION_ADPAGE_IMAGE_CAROUSEL_CONTACT);
        }
        if (this.modalPhotos.querySelector("#see-phone-button")) {
            this.sendApplyImpression(Section.SECTION_ADPAGE_IMAGE_CAROUSEL_PHONE);
        }
        const ChatSectionMap = new Map([
            ['button-chat-whatsapp-desktop', Section.SECTION_ADPAGE_IMAGE_CAROUSEL_WHATSAPP],
            ['button-chat-line-desktop', Section.SECTION_ADPAGE_IMAGE_CAROUSEL_LINE],
            ['button-chat-messenger-desktop', Section.SECTION_ADPAGE_IMAGE_CAROUSEL_FACEBOOK],
        ]);
        this.modalPhotos.querySelectorAll(".js-chat-button").forEach((element) => {
            this.sendApplyImpression(ChatSectionMap.get(element.id));
        });
    }

    sendApplyImpressionForVisitTab() {
        let scheduleTab = this.modalPhotos.querySelector(".js-scheduleViewing");
        if (scheduleTab) {
            this.sendApplyImpression(Section.SECTION_ADPAGE_IMAGE_CAROUSEL_VISIT);
        }
    }

    private sendApplyImpression(section: Section | undefined) {
        if (section) {
            this.adFormService.sendApplyImpression({
                pageViewId: this.pageViewId,
                propertyAdId: this.propertyAdId,
                section: section,
                step: 1,
                isProject: this.adFormService.isProjectPage(this.pageViewType),
            });
        }
    }
}
