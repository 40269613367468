import {EventBus} from "../../common/ts/events/EventBus";
import {
    AgencyPhoneModalOpenedEvent,
    ChatButtonClickedEvent,
    ChatButtonClickedEventPayload,
    ContactModalFormDisplayedEvent,
    FullPageModalOpenEvent,
    ObfuscatedElementClickedEvent,
    ObfuscatedElementClickedEventPayload,
    ProjectUnitButtonClickedEvent,
    ProjectUnitButtonClickedEventPayload,
    StickyContactButtonClickedEvent,
    StickyContactButtonClickedEventPayload,
    StickyScheduleButtonClickedEvent,
    WhitelabelEvent
} from "../../common/ts/events/Events";
import AdFormService from "../../common/ts/services/AdFormService";
import {Section} from "../../common/ts/services/Section";
import HipflatForm from "./HipflatForm";
import PageData from "../../common/ts/PageData";

export default class HipflatContactModal {

    private readonly eventBus: EventBus;
    private readonly adFormService: AdFormService;

    private constructor() {
        this.eventBus = EventBus.getInstance();
        this.adFormService = new AdFormService()
        this.setupEventListeners();
    }

    static init = (pageData: PageData) => {
        HipflatFormModal.init(pageData)
        HipflatScheduleModal.init(pageData)
        new HipflatContactModal()
    }

    setupEventListeners = () => {
        this.eventBus.subscribe(ContactModalFormDisplayedEvent.TYPE, (event: WhitelabelEvent) => {
            const payload = (event as ContactModalFormDisplayedEvent).payload;
            const section: Section = payload.section;
            const pageViewType: string = payload.pageViewType;
            let isProject: boolean = false;
            if (this.adFormService.isProjectPage(pageViewType) && section != Section.SECTION_ADPAGE_PROJECTS_MODEL_CONTACT) {
                isProject = true;
            }
            this.adFormService.sendApplyImpression({
                pageViewId: payload.pageViewId,
                propertyAdId: payload.propertyAdId,
                section: section,
                step: payload.step,
                isProject: isProject,
            });
        });
    }

};

class HipflatFormModal {
    private readonly eventBus: EventBus;
    private readonly contactModalId: string = 'contact-modal';
    private readonly form: HipflatForm

    private constructor(form: HipflatForm) {
        this.eventBus = EventBus.getInstance();
        this.form = form;

        this.setupEventListeners();
    }

    static init = (pageData: PageData) => {
        let requestForm = document.getElementById('contact-modal')?.querySelector('#contact-modal-form') || null;
        if (requestForm) {
            new HipflatFormModal(HipflatForm.initForm(requestForm as HTMLElement, pageData));
        }
    }

    setupEventListeners = () => {

        /*this.eventBus.subscribe(AgencyPhoneModalOpenedEvent.TYPE, (event: WhitelabelEvent) => {
            const payload = (event as AgencyPhoneModalOpenedEvent).payload;
            this.form.section = payload.section
        });*/

        this.eventBus.subscribe(ObfuscatedElementClickedEvent.TYPE, (event: WhitelabelEvent) => {
            const payload: ObfuscatedElementClickedEventPayload = (event as ObfuscatedElementClickedEvent).payload;
            if (payload.section === Section.SECTION_ADPAGE_DESCRIPTION_INFO) {
                this.open({
                    ...payload,
                    withoutMessageInput: false,
                });
            }
        });

        this.eventBus.subscribe(ChatButtonClickedEvent.TYPE, (event) => {
            const payload: ChatButtonClickedEventPayload = (event as ChatButtonClickedEvent).payload;
            this.open({
                ...payload,
                withoutMessageInput: true,
            });
        });

        this.eventBus.subscribe(ProjectUnitButtonClickedEvent.TYPE, (event: WhitelabelEvent) => {
            const payload: ProjectUnitButtonClickedEventPayload = (event as ProjectUnitButtonClickedEvent).payload;
            this.showElementsModalForm(payload.showOperationSelector, payload.showRentDatePicker, payload.isRent)
            this.open({
                ...payload,
                withoutMessageInput: false,
            });
        });

        this.eventBus.subscribe(StickyContactButtonClickedEvent.TYPE, (event: WhitelabelEvent) => {
            const payload: StickyContactButtonClickedEventPayload = (event as StickyContactButtonClickedEvent).payload;
            this.open({
                ...payload,
                withoutMessageInput: false,
            });
        });

    }

    private showElementsModalForm(showOperationSelector: boolean, showRentDatePicker: boolean, isRent: boolean) {
        const contactModal = document.getElementById('contact-modal');
        if (contactModal){
            const showOperationSelectorElement = contactModal.querySelector(".js-show-operation-selector") as HTMLElement;
            if (showOperationSelectorElement) {
                if (showOperationSelector) {
                    showOperationSelectorElement.classList.remove("hide")
                    let saleOperationSelector = contactModal.querySelector('.js-operation-type.sale') as HTMLInputElement;
                    saleOperationSelector.checked = true
                } else {
                    showOperationSelectorElement.classList.add("hide")
                }
            }

            if (isRent) {
                (contactModal.querySelector("#operation-type-rent-contact-modal-form") as HTMLInputElement).click();
            } else {
                (contactModal.querySelector("#operation-type-sale-contact-modal-form") as HTMLInputElement).click();
            }

            const showRentDatePickerElement = contactModal.querySelector(".js-show-rent-date-picker") as HTMLElement;
            if (showRentDatePickerElement) {
                if (showRentDatePicker) {
                    showRentDatePickerElement.classList.remove("hide")
                } else {
                    showRentDatePickerElement.classList.add("hide")
                }
            }
        }
    }

    private open = ({
                             section,
                             propertyAdId,
                             pageViewId,
                             step,
                             pageViewType,
                             withoutMessageInput,
                         }: {
        section: Section,
        propertyAdId: string,
        pageViewId: string,
        step: number,
        pageViewType: string,
        withoutMessageInput: boolean,
    }) => {
        this.form.reset();
        this.form.showHideMessageField(withoutMessageInput);
        this.form.section = section;
        this.form.listingId = propertyAdId;

        this.eventBus.emit(new FullPageModalOpenEvent({id: this.contactModalId}));
        this.eventBus.emit(new ContactModalFormDisplayedEvent({
            section: section,
            propertyAdId: propertyAdId,
            pageViewId: pageViewId,
            step: step,
            pageViewType: pageViewType,
        }));

    }
}

class HipflatScheduleModal {

    private readonly eventBus: EventBus;
    private readonly scheduleModalId: string = 'schedule-contact-modal';
    private readonly form: HipflatForm

    static init = (pageData: PageData) => {
        let scheduleForm = document.getElementById('schedule-contact-modal')?.querySelector('#contact-modal-form') || null;
        if (scheduleForm) {
            new HipflatScheduleModal(HipflatForm.initForm(scheduleForm as HTMLElement, pageData));
        }
    }

    private constructor(form: HipflatForm) {
        this.eventBus = EventBus.getInstance();
        this.form = form;
        this.setupEventListeners();
    }

    setupEventListeners = () => {
        this.eventBus.subscribe(StickyScheduleButtonClickedEvent.TYPE, (event: WhitelabelEvent) => {
            const payload: StickyContactButtonClickedEventPayload = (event as StickyContactButtonClickedEvent).payload;
            this.open({
                ...payload,
                hideMessageField: false,
            });
        });
    }

    private open = ({
                                     section,
                                     propertyAdId,
                                     pageViewId,
                                     step,
                                     pageViewType,
                                 }: {
        section: Section,
        propertyAdId: string,
        pageViewId: string,
        step: number,
        pageViewType: string,
        hideMessageField: boolean,
    }) => {
        this.form.reset();
        this.form.section = section;
        this.eventBus.emit(new FullPageModalOpenEvent({id: this.scheduleModalId}));
        this.eventBus.emit(new ContactModalFormDisplayedEvent({
            section: section,
            propertyAdId: propertyAdId,
            pageViewId: pageViewId,
            step: step,
            pageViewType: pageViewType,
        }));
    }
}
