import PageData from '../../PageData';
import {OperationsInterface} from "../Operations";
import {InternationalPhoneInput} from "./phone/InternationalPhoneInput";
import {PhoneValidationFactory} from "./phone/PhoneValidationFactory";
import {EmailValidationFactory} from "./email/EmailValidationFactory";
import {InputValidationHandler} from "./InputValidationHandler";
import {NameValidationHandler} from "./NameValidationHandler";
import {MessageValidationHandler} from "./MessageValidationHandler";

export class FormValidationsHandler {
    private readonly operations: OperationsInterface;
    private readonly phoneValidationHandler: InputValidationHandler;
    private inputValidationHandlers: Array<InputValidationHandler>;

    constructor(
        pageData: PageData,
        operations: OperationsInterface,
        nameElement: HTMLInputElement,
        emailElement: HTMLInputElement,
        phoneElement: HTMLInputElement,
        messageElement: HTMLTextAreaElement,
        internationalPhoneInput?: InternationalPhoneInput,
    ) {
        this.operations = operations
        this.phoneValidationHandler = PhoneValidationFactory.create(pageData, phoneElement, internationalPhoneInput);
        this.inputValidationHandlers =
            [
                NameValidationHandler.create(nameElement),
                EmailValidationFactory.create(pageData, emailElement),
                this.phoneValidationHandler,
                MessageValidationHandler.create(messageElement),
            ];
    }

    validateInputs(): boolean {
        let validRentDates = this.operations.validate()

        let allInputAreValid=
            this.inputValidationHandlers
                .map(value => { return value.validate() })
                .every(value => value)

        return !!validRentDates && allInputAreValid;
    }

    checkPhoneIsValid = () => {
        this.phoneValidationHandler.validate();
    }
}
