
export default class UserExitsButtonsComponent {
    private readonly backToSerpButtons: NodeListOf<Element>;

    constructor() {
        this.backToSerpButtons = document.querySelectorAll('button[data-back-to-serp]');
    }

    public static init = () => {
        new UserExitsButtonsComponent().setupEventListeners();
    };


    private setupEventListeners = () => {
        this.handleUserExitButtons();
    };

    private handleUserExitButtons = () => {
        const urlToSerp = localStorage.getItem("lastSerpUrl")
        this.backToSerpButtons.forEach((button) => {
            if (urlToSerp) {
                this.registerRedirectToSerpListener(button, urlToSerp);
            } else {
                this.hideSerpRedirectionElements(button);
            }
        })
    }

    private hideSerpRedirectionElements(button: Element) {
        const continueSearchTitle = document.querySelectorAll('[data-continue-search="true"]');
        const separator = document.querySelectorAll('[data-user-exits-separator="true"]');
        continueSearchTitle.forEach((element) => {
            element.classList.add("exits-message--hidden")
        })
        separator.forEach((element) => {
            element.classList.add("exits-options-separator--hidden")
        })
        button.classList.add("user-exit-button--hidden");
    }

    private registerRedirectToSerpListener(button: Element, urlToSerp: string) {
        button.addEventListener('click', () => {
            window.location.href = urlToSerp;
            // @ts-ignore
            gtag('event', button?.dataset.backToSerp);
        })
    }
}
